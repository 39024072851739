import http from '@/models/http'

const getContent = http.get('/content')

const sendCsrMsg = http.post('/csr_msg')

const getBlog = http.post('/blog')

const getBlogList = http.post('blogList')

export {
  getContent,
  sendCsrMsg,
  getBlog,
  getBlogList
}

export default {
  getContent,
  sendCsrMsg,
  getBlog,
  getBlogList
}
