import Vue from 'vue'

// Mods
import BootstrapVue from 'bootstrap-vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueMeta from 'vue-meta'
import VueSilentbox from 'vue-silentbox'
import BackToTop from 'vue-backtotop'
const loadMods = async() => {
  Vue.use(VueSilentbox)
  Vue.use(BootstrapVue)
  Vue.use(BackToTop)
  Vue.use(VueAwesomeSwiper)
  Vue.use(VueMeta, {
  // optional pluginOptions
    refreshOnceOnNavigation: true
  })
}

// GlobalData
import api from '@/api'
const initGlobalData = async() => {
  Vue.prototype.$api = api
  Vue.prototype.$d = (await api.getContent()).content
}

// Stylesheets
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '../src/assets/css/font-awesome.min.css'
import '../src/assets/css/material-design-iconic-font.min.css'
import '../src/assets/css/ionicons.min.css'
import '../src/assets/css/flaticon.min.css'
import 'swiper/css/swiper.min.css'
import '../src/assets/css/animate.min.css'
import '../src/assets/scss/style.scss'

// App
import App from './App.vue'
import router from './router'
const app = async() => {
  Vue.config.productionTip = false
  new Vue({
    router,
    render: h => h(App)
  }).$mount('#app')
}

// Main Process
const main = async() => {
  await loadMods()
  await initGlobalData()
  await app()
}
main()
