import Axios from 'axios'

const http = Axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? 'https://backend.oihchina.com/web' : 'http://localhost:3000/web',
  timeout: 10000
})

const before = async() => {}

const after = async(res) => {
  return res.data
}

const get = (url) => async() => {
  await before()
  const res = await http.get(url)
  return await after(res)
}

const post = (url) => async(data) => {
  await before()
  const res = await http.post(url, data)
  return await after(res)
}

export default {
  get,
  post
}
